import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import SharingPermissionSelect from '@components/Formik/Select/SharingPermissionSelect';
import TextArea from '@components/Formik/TextArea';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { backgroundValidationSchema } from '../validationSchema';

import { MAX_BACKGROUND_CHAR } from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useFetchBackground,
  useUpdateBackground,
} from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { FetchBackgroundQuery } from '@typings/operations';

interface Props extends RouteComponentProps {
  individualId?: string;
}

type FormValues = FetchBackgroundQuery['background'];

const BackgroundEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();

  const { data, loading: fetchBackgroundLoading, error } = useFetchBackground(
    individualId || ''
  );

  useErrorHandling(error?.message, !!error, 'fetch-background.error');

  const [
    updateBackground,
    { loading: updateBackgroundLoading },
  ] = useUpdateBackground(individualId || '');

  const handleFormSubmit = async (values: FormValues) => {
    try {
      await updateBackground({
        variables: {
          id: individualId,
          ...values,
          // The last level (id) needs to be removed.
          sharingPermissionsExtended: {
            aboutMe: values?.sharingPermissionsExtended?.aboutMe.id,
          },
        },
      });

      localizedNavigate(`/profile/${individualId}`);
      addSuccess(t('edit-background.form.success', 'Update successful.'), {
        id: 'form.success',
      });
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
    }
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  if (fetchBackgroundLoading || updateBackgroundLoading || !data?.background) {
    return <Loading />;
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={data.background}
        onSubmit={handleFormSubmit}
        validationSchema={backgroundValidationSchema(t)}
      >
        {({ isValid, handleSubmit }) => (
          <>
            <LinkPrevious
              path={`/profile/${individualId}`}
              label={t('edit-profile.back-link', 'Profile')}
            />
            <Title>{t('edit-background.title', 'Edit Background')}</Title>
            <Form>
              <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                <div className="desktop:flex-1 mb-10 desktop:mb-0">
                  {data.background.sharingPermissionsExtended && (
                    <SharingPermissionSelect selectName="sharingPermissionsExtended.aboutMe.id" />
                  )}
                </div>
                <div className="desktop:flex-2 desktop:mr-24">
                  <TextArea
                    name="aboutMe"
                    label={t('edit-background.form.about-me-label', 'About Me')}
                    characters={MAX_BACKGROUND_CHAR}
                  />
                </div>
              </div>
              <div className="max-w-lg">
                <Button
                  full
                  className="mt-16 mb-6"
                  clickHandler={() => {
                    if (!isValid)
                      addError(
                        t(
                          'edit-background.form.error-msg',
                          'Please correct the invalid fields'
                        ),
                        { id: 'form.error' }
                      );
                    handleSubmit();
                  }}
                >
                  {t('edit-background.form.submit-label', 'Save Changes')}
                </Button>
                <Button type="button" full text clickHandler={handleCancel}>
                  {t('edit-background.form.cancel-label', 'Cancel')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </OneColumn>
  );
};

export default BackgroundEdit;
