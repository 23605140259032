import React from 'react';

import { RouteComponentProps } from '@reach/router';

import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';

import AreasOfExpertise from './AreasOfExpertise';
import Background from './Background';
import ContactInformation from './ContactInformation';
import PersonalDetails from './PersonalDetails';
import ProfessionalExperience from './ProfessionalExperience';
import Resume from './Resume';
import RotaryPrograms from './RotaryPrograms';

import { useErrorHandling } from '@use-cases/notifications';

import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const ProfileView: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();

  const { data, loading, error } = useFetchProfileDetails(individualId || '');

  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );
  if (error || !individualId) {
    return <p>error</p>;
  }

  if (!data || loading) {
    return <Loading />;
  }

  const {
    firstName,
    lastName,
    middleName,
    localizedName,
    location,
    riIndividualId,
    nfKey,
    primaryEmail,
    primaryAddress,
    primaryPhone,
    otherPhones,
    otherAddresses,
    otherEmails,
    aboutMe,
    groupedExpertise,
    primaryProfession,
    otherProfessions,
    programs,
    resumeRoles,
    resumeRecognitions,
    resumeSponsorships,
    editPermissions,
    clubAffiliations,
    sharingPermissionsExtended,
    suffix,
    prefix,
    gender,
    dateOfBirth,
    youthFlag,
    photoUri,
  } = data.individual;

  const isResumeRolesExists = Boolean(resumeRoles.resumeRolesGroups?.length);

  const handleBackToPreviousPage = () => {
    const previousPathUrl = window.sessionStorage.getItem('previousPagePath');

    if (!previousPathUrl) return window.history.back();

    window.sessionStorage.removeItem('previousPagePath');
    return localizedNavigate(previousPathUrl);
  };

  return (
    <OneColumn className="mb-20">
      <span
        onClick={() => handleBackToPreviousPage()}
        className="inline-flex items-center font-bold text-bright-blue-600 link-styles text-xs leading-xs-heading mb-8"
      >
        <Icon name="arrows/left" className="mr-2" size="12" />
        {t('view-profile.back-link', 'Back to previous page')}
      </span>
      <PersonalDetails
        {...{
          firstName,
          lastName,
          middleName,
          localizedName,
          riIndividualId,
          individualId,
          nfKey,
          hasEditPermission: editPermissions?.contactInformation || false,
          location,
          photoUri,
          suffix,
          prefix,
          gender,
          dateOfBirth,
          youthFlag,
          clubAffiliations,
          isResumeRolesExists,
        }}
      />
      <ContactInformation
        {...{
          primaryEmail,
          primaryAddress,
          primaryPhone,
          otherPhones,
          otherEmails,
          otherAddresses,
          individualId,
          hasEditPermission: editPermissions?.contactInformation || false,
        }}
      />
      <Background
        {...{
          aboutMe,
          individualId,
          hasEditPermission: editPermissions?.background || false,
        }}
      />
      <Resume
        {...{
          resumeRoles,
          resumeRecognitions,
          resumeSponsorships,
          hasEditPermission:
            (editPermissions?.recognition &&
              !!sharingPermissionsExtended?.recognition?.id) ||
            false,
          individualId,
        }}
      />
      <RotaryPrograms
        {...{
          programs,
          individualId,
          hasEditPermission: editPermissions?.rotaryPrograms || false,
        }}
      />
      <ProfessionalExperience
        {...{
          individualId,
          primaryProfession,
          otherProfessions,
          hasEditPermission: editPermissions?.professionalExperience || false,
        }}
      />
      <AreasOfExpertise
        {...{
          individualId,
          areasOfExpertise: groupedExpertise,
          hasEditPermission: editPermissions?.areasOfExpertise || false,
        }}
      />
    </OneColumn>
  );
};

export default ProfileView;
