import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { FieldArray, Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Select from '@components/Formik/Select';
import SharingPermissionSelect from '@components/Formik/Select/SharingPermissionSelect';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import AddAnotherButton from '../components/ButtonAddAnother';
import DeleteButton from '../components/ButtonDelete';
import PrimaryRadio from '../components/PrimaryRadio';
import { areasOfExpertiseValidationSchema } from '../validationSchema';

import {
  EditProfileSharingPermission,
  FormExpertiseItem,
  mapAreasOfExpertiseDataToFormValues,
  mapFormValuesToUpdateAreasOfExpertiseInput,
} from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useFetchAreaOfExpertise,
  useUpdateAreasOfExpertiseMutation,
} from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId: string;
}
export type EditExpertisesSharingPermissions = {
  expertise: EditProfileSharingPermission;
};
export type EditExpertisesFormValues = {
  expertises: FormExpertiseItem[];
  sharingPermissionsExtended: EditExpertisesSharingPermissions;
};

const AreasOfExpertiseEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();

  const { data, loading, error } = useFetchAreaOfExpertise(individualId);

  useErrorHandling(error?.message, !!error, 'area-of-expertise.error');

  const [
    updateAresOfExpertise,
    { loading: updating, error: mutationError },
  ] = useUpdateAreasOfExpertiseMutation();

  useErrorHandling(
    mutationError?.message,
    !!error,
    'update-area-of-expertise.error'
  );

  const handleFormSubmit = async (values: EditExpertisesFormValues) => {
    const mutationValues = mapFormValuesToUpdateAreasOfExpertiseInput(values);

    try {
      await updateAresOfExpertise({
        variables: {
          individualId,
          ...mutationValues,
        },
      });

      localizedNavigate(`/profile/${individualId}`);
      addSuccess(
        t('edit-professional-experience.form.success', 'Update successful.'),
        { id: 'form.success' }
      );
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
    }
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  if (loading || updating || !data?.areasOfExpertise) {
    return <Loading />;
  }

  const formValues = mapAreasOfExpertiseDataToFormValues(data.areasOfExpertise);
  const { expertiseAreas, expertiseLevels } = data;

  if (formValues.expertises.length === 0) {
    formValues.expertises = [
      {
        areaId: '',
        area: '',
        level: '',
        levelId: '',
        isPrimary: true,
      },
    ];
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmit}
        validationSchema={areasOfExpertiseValidationSchema(t)}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const selectPrimary = (selectedIndex: number) => {
            setFieldValue(
              'expertises',
              values.expertises.map(
                (expertise: FormExpertiseItem, index: number) =>
                  index === selectedIndex
                    ? { ...expertise, isPrimary: true }
                    : { ...expertise, isPrimary: false }
              )
            );
          };
          const multipleExpertises = values.expertises.length > 1;
          const makePrimary = values.expertises.length < 1;

          return (
            <>
              <LinkPrevious
                path={`/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>
                {t('edit-areas-of-expertise.title', 'Edit Areas Of Expertise')}
              </Title>
              <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                  {formValues.sharingPermissionsExtended && (
                    <SharingPermissionSelect selectName="sharingPermissionsExtended.expertise.id" />
                  )}
                </div>
                <div className="desktop:flex-2 desktop:mr-24">
                  <Form className="max-w-lg">
                    <FieldArray
                      name="expertises"
                      render={({ push, remove }) => (
                        <>
                          {values.expertises.map((expertise, i) => (
                            <div
                              key={`${expertise}`}
                              className="pb-8 mb-8 border-b border-gray-300 border-dotted"
                            >
                              <Select
                                name={`expertises[${i}].areaId`}
                                label={`${t(
                                  'edit-area-of-expertise.form.area.label',
                                  'Area of Expertise'
                                )}<span class="sr-only"> ${i + 1}</span>`}
                                options={expertiseAreas.map(
                                  ({ label, id }) => ({
                                    label,
                                    value: id,
                                  })
                                )}
                                required
                              />
                              <Select
                                name={`expertises[${i}].levelId`}
                                label={`${t(
                                  'edit-area-of-expertise.form.level.label',
                                  'Level of Expertise'
                                )}<span class="sr-only"> ${i + 1}</span>`}
                                options={expertiseLevels.map(
                                  ({ label, id }) => ({
                                    label,
                                    value: id,
                                  })
                                )}
                                required
                              />
                              <div className="mt-8 flex">
                                <PrimaryRadio
                                  checked={expertise.isPrimary}
                                  id={`primary-radio-${i}`}
                                  name="primaryOccupation"
                                  onClick={() => selectPrimary(i)}
                                  label={`<span class="sr-only">select expertise-${i}</span> ${t(
                                    'edit-professional-experience.primary-label',
                                    'Primary'
                                  )}`}
                                />
                                {((multipleExpertises &&
                                  !expertise.isPrimary) ||
                                  !multipleExpertises) && (
                                  <DeleteButton onClick={() => remove(i)} />
                                )}
                              </div>
                            </div>
                          ))}
                          <AddAnotherButton
                            label={t(
                              'edit-areas-of-expertise.add-label',
                              'Add another area of expertise'
                            )}
                            onClick={() =>
                              push({
                                areaId: '',
                                levelId: '',
                                isPrimary: makePrimary,
                              })
                            }
                          />
                          <Button
                            full
                            className="mt-16 mb-6"
                            clickHandler={() => {
                              handleSubmit();
                            }}
                          >
                            {t(
                              'edit-professional-experience.form.submit-label',
                              'Save Changes'
                            )}
                          </Button>
                          <Button
                            type="button"
                            full
                            text
                            clickHandler={handleCancel}
                          >
                            {t(
                              'edit-professional-experience.form.cancel-label',
                              'Cancel'
                            )}
                          </Button>
                        </>
                      )}
                    />
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default AreasOfExpertiseEdit;
