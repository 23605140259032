import React from 'react';
import { useTranslation } from '@external/react-i18next';

const Info = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t(
        'edit-profile.programs.edit.info',
        'Only include programs you directly participated in. Do not include Rotary Foundation humanitarian projects, including matching or global grants, or programs where you served as an advisor, counselor, sponsor, chair, or host.'
      )}
    </p>
  );
};

export default Info;
