import React, { useState } from 'react';

import classNames from 'classnames';
import { groupBy, map, partition, sortBy } from 'lodash';

import OrganisationTitle from './OrganisationTitle';
import RolesList from './RolesList';

import { mergeRoles } from '@use-cases/profile';

import { useTranslation } from '@external/react-i18next';

import { ResumeItem } from '@typings/graphql';

const ResumeRoles: React.FC<{ resumeRole: ResumeItem }> = ({ resumeRole }) => {
  const { t } = useTranslation();

  const { roles } = resumeRole;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const organisationTitle = <OrganisationTitle resumeRole={resumeRole} />;

  // Group & sort committee roles by committeeName
  const mergedRoles = mergeRoles(roles);

  const groupByCommittee = groupBy(mergedRoles, 'committeeName');
  const orderByCommittee = map(groupByCommittee, (groupedRoles, key) => ({
    committeeName: key === 'null' ? null : key,
    roles: groupedRoles,
  }));

  const [nonCommitteeRoles, committeeRoles] = partition(orderByCommittee, {
    committeeName: null,
  });

  const committeRolesList = [
    ...nonCommitteeRoles,
    ...sortBy(committeeRoles, 'committeeName'),
  ];

  const isCommitteeRolesExist = committeRolesList.length > 0;

  let indexPair: number[] = [];
  let count = 0;

  // Show by default only 10 roles.
  committeRolesList.forEach((rolesGroup, groupIndex) => {
    rolesGroup.roles.forEach((role, roleIndex) => {
      count += 1;

      // Set index of role and role's group if count is more then 10
      if (count === 11) {
        indexPair = [groupIndex, roleIndex];
      }
    });
  });

  return (
    <div className="mb-6">
      {isCommitteeRolesExist && organisationTitle}

      {committeRolesList.map(({ committeeName, roles }, i) => {
        const index = i;

        // Title/role should be hidden if groupIndex and roleIndex are the same as i and index accordingly
        // or when groupIndex is less then i
        const isHiddenItem = (roleIndex?: number) =>
          isCollapsed &&
          (index > indexPair?.[0] ||
            (index === indexPair?.[0] && indexPair?.[1] === (roleIndex || 0)));

        return (
          <div key={`${index}-${committeeName}-role`}>
            {!!committeeName && (
              <b
                className={classNames('text-gray-400', {
                  hidden: isHiddenItem(),
                })}
              >
                {committeeName}
              </b>
            )}
            <RolesList
              roles={roles}
              isHidden={isHiddenItem}
              isPadding={!!committeeName}
            />
          </div>
        );
      })}

      {!!indexPair.length && (
        <div
          className="link-styles w-fit hover:shadow-none"
          onClick={handleCollapse}
        >
          {isCollapsed
            ? t('resume.show-button', 'Show all')
            : t('resume.hide-button', 'Hide')}
        </div>
      )}
    </div>
  );
};

export default ResumeRoles;
