// Libs
import React from 'react';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Components
import Icon from '@components/Icon';

const SharingPermissionMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <p className="max-w-lg desktop:flex-1 flex-shrink-0 flex items-center self-start text-xs leading-xs">
      <Icon
        aria-hidden={true}
        name="circles/notification"
        size="16"
        className="flex-shrink-0"
      />
      <span className="ml-2">
        {t(
          'edit-personal-details.sharing-personal-information-message',
          'Personal Information is shared with everyone'
        )}
      </span>
    </p>
  );
};

export default SharingPermissionMessage;
