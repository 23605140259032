// Libs
import React, { ReactChild } from 'react';
import { Router, RouteComponentProps } from '@reach/router';

// Hooks
import { useFetchIndividualPrograms } from '@repositories/profile/hooks';

// Components
import Loading from '@components/Loading';

// Types
import { ProgramDefinition } from '@typings/graphql';
import { Program, FetchEditProgramsQuery } from '@typings/operations';
import { QueryResult } from '@apollo/client';
import { EditProfileSharingPermission } from '@domain/profile';

// Utils
import { localizedNavigate } from '@utils/localized-navigate';

export type EditProgramsSharingPermissions = {
  program: EditProfileSharingPermission;
};

type ChildProps = {
  individualId: string;
  programs: ProgramDefinition[];
  individualPrograms: Program[];
  sharingPermissionsExtended: EditProgramsSharingPermissions | null;
  refetch: QueryResult<FetchEditProgramsQuery>['refetch'];
};

interface Props extends RouteComponentProps {
  individualId?: string;
  render: (props: ChildProps) => ReactChild;
}

const EditPrograms: React.FC<Props> = ({ render, individualId }) => {
  const { data, loading, error, refetch } = useFetchIndividualPrograms(
    individualId || ''
  );
  if (!individualId) {
    localizedNavigate(`/profile/${individualId}`);
    return <Loading />;
  }
  if (loading) {
    return <Loading />;
  }
  if (error || !data || !data.programs || !data.individual) {
    return <p>Error</p>;
  }

  return (
    <Router>
      {render({
        individualId,
        programs: data.programs,
        individualPrograms: data.individual.programs,
        sharingPermissionsExtended:
          data.individual.sharingPermissionsExtended || null,
        refetch,
      })}
    </Router>
  );
};

export default EditPrograms;
