// Libs
import React from 'react';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Components
import Divider from '@components/Divider';
import LinkEdit from '@components/LinkEdit';
import Experience from './Experience';

// Types
import { FetchProfileQuery } from '@typings/operations';

type Props = Pick<
  FetchProfileQuery['individual'],
  'primaryProfession' | 'otherProfessions'
> & {
  individualId: string;
  hasEditPermission: boolean;
};

const ProfessionalExperience: React.FC<Props> = ({
  individualId,
  primaryProfession,
  otherProfessions,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>
          {t(
            'view-profile.professional-experience.title',
            'Professional Experience'
          )}
        </h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/profile/${individualId}/edit-professional-experience`}
            component={t(
              'view-profile.professional-experience.title',
              'Professional Experience'
            )}
          />
        )}
      </div>
      {primaryProfession && (
        <>
          <div className="mb-8">
            <h3 className="h4 alternate">
              {t('view-profile.professional-experience.primary', 'Primary')}
            </h3>
            <Experience profession={primaryProfession} />
          </div>
          {!!otherProfessions?.length && (
            <>
              <h3 className="h4 alternate">
                {t('view-profile.professional-experience.further', 'Further')}
              </h3>
              {otherProfessions.map((profession, i) => (
                <Experience
                  key={`${i}-${profession.position}`}
                  profession={profession}
                />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProfessionalExperience;
