import React, { Dispatch, SetStateAction } from 'react';

import classNames from 'classnames';
import { isEmpty, uniqBy } from 'lodash';

import Link from '@components/Link';

import { getClubTypeName } from '@utils/getClubTypeName';
import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import {
  FormattedClub,
  FormattedMembership,
  SponsorshipItem,
  SponsorshipResult,
} from '@typings/graphql';
import { Enum, From, mapEnum } from '@typings/map-enum';

interface Props {
  resumeSponsorships: SponsorshipResult;
}

interface ShowAllProperties {
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
}

const ResumeSponsorships: React.FC<Props> = ({
  resumeSponsorships: { sponsoredByMe, sponsoredBySomeone },
}) => {
  const { t } = useTranslation();
  const [showAllSponsoredByMe, setShowAllSponsoredByMe] = React.useState(false);
  const [
    showAllSponsoredBySomeone,
    setShowAllSponsoredBySomeone,
  ] = React.useState(false);

  const renderClub = ({ name, location, type }: FormattedClub) => (
    <h4 className="h5">
      {name}, {getPhysicalLocation(location)} (
      {getClubTypeName(
        t,
        mapEnum(From.DIS, Enum.ClubType, type as DIS.ClubType)
      )}
      )
    </h4>
  );

  const getShowAllProperties = (isSponsoredByMe: boolean): ShowAllProperties =>
    isSponsoredByMe
      ? { showAll: showAllSponsoredByMe, setShowAll: setShowAllSponsoredByMe }
      : {
          showAll: showAllSponsoredBySomeone,
          setShowAll: setShowAllSponsoredBySomeone,
        };

  const renderShowAll = ({ showAll, setShowAll }: ShowAllProperties) =>
    !showAll && (
      <div className="inline link-styles" onClick={() => setShowAll(true)}>
        {t('view-profile.resume.sponsorship-show-all', 'Show all')}
      </div>
    );

  const renderMembershipList = (
    membershipList: FormattedMembership[],
    isSponsoredByMe: boolean
  ) => {
    const filteredMemberships: FormattedMembership[] = uniqBy(
      membershipList,
      'individualId'
    );

    let membershipsArray: FormattedMembership[] = filteredMemberships;

    const { showAll, setShowAll } = getShowAllProperties(isSponsoredByMe);

    if (filteredMemberships.length > 10 && !showAll) {
      membershipsArray = filteredMemberships.slice(0, 10);
    } else {
      !showAll && setShowAll(true);
    }

    return membershipsArray.map(
      ({ individualId, isTerminated, fullName, startDate }) => (
        <div
          key={individualId}
          className={classNames('text-md leading-6 mb-0')}
        >
          {!isTerminated ? (
            <Link
              className={classNames('text-md leading-6 mb-0', {
                underline: !isTerminated,
              })}
              noLinkStyles
              to={`/profile/${individualId}`}
            >
              {fullName}
            </Link>
          ) : (
            fullName
          )}{' '}
          ({startDate})
        </div>
      )
    );
  };

  const renderSponsorsList = (
    sponsorsList: SponsorshipItem[],
    title: string,
    isSponsoredByMe = false
  ) =>
    !isEmpty(sponsorsList) && (
      <div>
        <h3 className="h5 alternate">{title}</h3>
        {sponsorsList.map(({ club, membershipList }) => (
          <div className="mb-6" key={club.clubId}>
            {renderClub(club)}
            {renderMembershipList(membershipList, isSponsoredByMe)}
            {renderShowAll(getShowAllProperties(isSponsoredByMe))}
          </div>
        ))}
      </div>
    );

  return (
    <div>
      {!(isEmpty(sponsoredByMe) && isEmpty(sponsoredBySomeone)) && (
        <div className="mb-10">
          <h3 className="h4 alternate">
            {t(
              'view-profile.resume.sponsorship-title',
              'Member Sponsorship History'
            )}
          </h3>
          <div className="mb-2">
            {renderSponsorsList(
              sponsoredByMe,
              t('view-profile.resume.sponsored-members', 'Sponsored Members'),
              true
            )}
          </div>
          <div>
            {renderSponsorsList(
              sponsoredBySomeone,
              t('view-profile.resume.sponsored-by', 'Sponsored By')
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeSponsorships;
