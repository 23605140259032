import React from 'react';

import Link from '@components/Link';

import { getClubDetailsPath } from '@use-cases/clubs';
import {
  getOrganizationLocationAndSuffix,
  getOrganizationName,
} from '@use-cases/profile';

import { getDistrictDetailsPath } from '@utils/districts';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { ResumeItem } from '@typings/graphql';

type Props = {
  resumeRole: ResumeItem;
};

const OrganisationTitle: React.FC<Props> = ({ resumeRole }) => {
  const { t } = useTranslation();

  const { organizationId, organizationType } = resumeRole;

  const organizationName = getOrganizationName(resumeRole, t);
  const organizationLocationAndSuffix = getOrganizationLocationAndSuffix(
    resumeRole,
    t
  ).trim();

  const locationAndSuffix = `${
    organizationLocationAndSuffix ? `, ${organizationLocationAndSuffix}` : ''
  }`;

  // Link should be displayed for all active/suspended organisation types (clubs & districts) except Rotary Office
  if (
    organizationId &&
    organizationType !== DIS.ResumeRolesOrganizationType['Rotary Office']
  ) {
    let linkHref = '';

    if (
      organizationType === DIS.ResumeRolesOrganizationType.Club ||
      organizationType === DIS.ResumeRolesOrganizationType['Rotaract Club']
    ) {
      linkHref = getClubDetailsPath(organizationId);
    } else if (organizationType === DIS.ResumeRolesOrganizationType.District) {
      linkHref = getDistrictDetailsPath(organizationId);
    }

    return (
      <h4 className="mb-2 leading-normal text-base">
        <Link to={linkHref}>{organizationName}</Link>
        {locationAndSuffix}
      </h4>
    );
  }

  return (
    <h4 className="mb-2 leading-normal text-base">{`${organizationName} ${locationAndSuffix}`}</h4>
  );
};

export default OrganisationTitle;
