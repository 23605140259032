import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import {
  ClubAffiliation,
  ClubType,
  MembershipAffiliation,
  MembershipType,
} from '@typings/graphql';

export type ClubAffiliationDetails = {
  clubId: string;
  clubName: string;
  clubType: ClubType;
  clubLocation: string;
  membership?: MembershipAffiliation;
  role?: string;
};

const findMembershipAffiliation = (
  clubAffiliation: ClubAffiliation,
  membershipTypes?: MembershipType[]
) => {
  return clubAffiliation.affiliations.find(
    item =>
      item.__typename === 'MembershipAffiliation' &&
      (!membershipTypes || membershipTypes.includes(item.membershipType))
  ) as MembershipAffiliation;
};

const formatClubAffiliation = (
  clubAffiliation: ClubAffiliation
): ClubAffiliationDetails => {
  return {
    clubId: clubAffiliation.clubId,
    clubName: clubAffiliation.clubName,
    clubType: clubAffiliation.clubType,
    clubLocation: getPhysicalLocation(clubAffiliation.clubLocation) || '',
    membership: findMembershipAffiliation(clubAffiliation),
  };
};

/**
 * If the individual holds both an active Member and an active Rotaractor, display both clubs in aplha order
 * If the individual is only an honorary member display the oldest active honorary relationship
 * If the individual is only an honorary member but honorary in both Rotaract and Rotary Clubs, display both club types in alpha order with the oldes active for each
 */

export const getClubAffiliationDetails = (
  clubAffiliations: ClubAffiliation[]
): ClubAffiliationDetails[] => {
  const clubsWithMembership = clubAffiliations
    .filter(affiliation =>
      findMembershipAffiliation(affiliation, [
        MembershipType.Member,
        MembershipType.Rotaractor,
      ])
    )
    .map(formatClubAffiliation);

  if (clubsWithMembership.length) {
    return clubsWithMembership.sort(
      (a, b) =>
        a.clubName.localeCompare(b.clubName) ||
        a.clubType.localeCompare(b.clubType)
    );
  }

  const clubsWithHonoraryMembership = clubAffiliations
    .filter(affiliation =>
      findMembershipAffiliation(affiliation, [
        MembershipType.Honorary,
        MembershipType.RotaractHonorary,
      ])
    )
    .map(formatClubAffiliation)
    .sort((a, b) => {
      const admissionDateA = a?.membership?.admissionDate || '';
      const admissionDateB = b?.membership?.admissionDate || '';

      return admissionDateA.localeCompare(admissionDateB);
    });

  if (clubsWithHonoraryMembership.length) {
    const rotaryClub = clubsWithHonoraryMembership.find(
      ({ clubType }) => clubType === ClubType.RotaryClub
    );

    const rotaractClub = clubsWithHonoraryMembership.find(
      ({ clubType }) => clubType === ClubType.RotaractClub
    );

    return [rotaryClub, rotaractClub]
      .filter((club): club is ClubAffiliationDetails => Boolean(club))
      .sort(
        (a, b) =>
          a.clubName.localeCompare(b.clubName) ||
          a.clubType.localeCompare(b.clubType)
      );
  }

  return [];
};
