import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import SharingPermissionSelect from '@components/Formik/Select/SharingPermissionSelect';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import ResumeRecognitions from '../../View/Resume/components/ResumeRecognition';

import { useNotifications } from '@use-cases/notifications';

import { useFetchResume, useUpdateResume } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

type FormValues = {
  sharingPermissionsExtended: {
    recognition: { id: string | undefined };
  };
};
interface Props extends RouteComponentProps {
  individualId?: string;
}
const ResumeEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading } = useFetchResume(individualId!);
  const [updateResume, { loading: updating }] = useUpdateResume();

  const handleFormSubmit = async (values: FormValues) => {
    await updateResume({
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: individualId!,
        sharingPermissionsExtended: {
          recognition: values?.sharingPermissionsExtended?.recognition.id,
        },
      },
    });
    addSuccess(t('edit-resume.form.success', 'Update successful.'), {
      id: 'form.success',
    });
    localizedNavigate(`/profile/${individualId}`);
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  const formValues = {
    sharingPermissionsExtended: {
      recognition: {
        id: data?.resume?.sharingPermissionsExtended?.recognition.id,
      },
    },
  };

  const resumeRecognitions = data?.resume.resumeRecognitions || [];
  if (loading || updating || !data?.resume) {
    return <Loading />;
  }

  return (
    <OneColumn className="mb-20">
      <Formik initialValues={formValues} onSubmit={handleFormSubmit}>
        {() => (
          <>
            <LinkPrevious
              path={`/profile/${individualId}`}
              label={t('edit-profile.back-link', 'Back')}
            />
            <Title>{t('edit-resume.title', 'Edit visibility')}</Title>
            <div
              data-testid="edit-resume-info-message"
              className="mb-8 desktop:w-64pct tablet:w-64pct"
            >
              {t(
                'edit-resume.info.message',
                'Your membership information and sponsorship history are visible to anyone who views your profile. Who can see your Rotary Foundation recognition information depends on your permissions. Use the "Who can see this" menu to change them.'
              )}
            </div>
            <Form>
              <div className="flex justify-between items-start">
                <div className="mb-10">
                  <h3 className="h4 alternate">
                    {t(
                      'edit-resume.resume.recognition-title',
                      'Rotary Foundation Recognition'
                    )}
                  </h3>
                  {resumeRecognitions.length ? (
                    <div data-testid="resume-recognition">
                      {resumeRecognitions.map((resumeRecognition, i) => (
                        <ResumeRecognitions
                          resumeRecognition={resumeRecognition}
                          key={`${resumeRecognition.type + i}`}
                        />
                      ))}
                    </div>
                  ) : (
                    <div>
                      {t('edit-resume.recognitions.no.data', 'No data')}
                    </div>
                  )}
                  <div className="w-96">
                    <Button full className="mt-16 mb-6">
                      {t('edit-resume.form.submit-label', 'Save Changes')}
                    </Button>
                    <Button type="button" full text clickHandler={handleCancel}>
                      {t('edit-resume.form.cancel-label', 'Cancel')}
                    </Button>
                  </div>
                </div>
                <div className="w-1/3" data-testid="sharing-permission-select">
                  {data.resume.sharingPermissionsExtended && (
                    <SharingPermissionSelect selectName="sharingPermissionsExtended.recognition.id" />
                  )}
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </OneColumn>
  );
};

export default ResumeEdit;
