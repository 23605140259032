import i18n from 'i18next';

import { SelectOptions } from '@components/Formik/Select';

import { Gender } from '@typings/graphql';

const { t } = i18n;

export const genderSelectOptions: SelectOptions = [
  {
    label: t('global.gender.male', 'Male'),
    value: Gender.Male,
  },
  {
    label: t('global.gender.female', 'Female'),
    value: Gender.Female,
  },
  {
    label: t('global.gender.self-describe', 'Self-describe'),
    value: Gender.Self,
  },
  {
    label: t('global.gender.do-not-identify', 'Prefer to Not Identify'),
    value: Gender.Dni,
  },
];
