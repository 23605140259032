import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { FieldArray, Form, Formik } from 'formik';

import { Button } from '@components/Button';
import SharingPermissionSelect from '@components/Formik/Select/SharingPermissionSelect';
import TextField from '@components/Formik/TextField';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import AddAnotherButton from '../components/ButtonAddAnother';
import DeleteButton from '../components/ButtonDelete';
import PrimaryRadio from '../components/PrimaryRadio';
import { professionalExperienceValidationSchema } from '../validationSchema';
import { selectProfessionLabelValue } from './utils';

import {
  EditProfessionsFormValues,
  FormProfessionItem,
  mapFormValuesToUpdateProfessionalExperienceInput,
  mapProfessionalExperienceDataToFormValues,
} from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  useFetchProfessionalExperience,
  useUpdateProfessionalExperience,
} from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId: string;
}

const ProfessionalExperienceEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const { data, loading, error } = useFetchProfessionalExperience(individualId);

  useErrorHandling(error?.message, !!error, 'fetch.error');

  const [
    updateProfessionalExperience,
    { loading: updating, error: mutationError },
  ] = useUpdateProfessionalExperience();
  useErrorHandling(mutationError?.message, !!error, 'mutation.error');

  const handleFormSubmit = async (values: EditProfessionsFormValues) => {
    const mutationValues = mapFormValuesToUpdateProfessionalExperienceInput(
      values
    );

    await updateProfessionalExperience({
      variables: {
        id: individualId,
        ...mutationValues,
      },
    });

    localizedNavigate(`/profile/${individualId}`);
    addSuccess(
      t('edit-professional-experience.form.success', 'Update successful.'),
      { id: 'form.success' }
    );
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  if (loading || updating || !data?.professionalExperience) {
    return <Loading />;
  }

  const formValues = mapProfessionalExperienceDataToFormValues(
    data.professionalExperience
  );

  if (formValues.professionalExperiences.length === 0) {
    formValues.professionalExperiences = [
      {
        occupation: '',
        employer: '',
        position: '',
        isPrimary: true,
        id: '',
      },
    ];
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmit}
        validationSchema={professionalExperienceValidationSchema(t)}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const selectPrimary = (selectedIndex: number) => {
            setFieldValue(
              'professionalExperiences',
              values.professionalExperiences.map(
                (profession: FormProfessionItem, index: number) =>
                  index === selectedIndex
                    ? { ...profession, isPrimary: true }
                    : { ...profession, isPrimary: false }
              )
            );
          };
          const multipleProfessions = values.professionalExperiences.length > 1;
          const makePrimary = values.professionalExperiences.length < 1;

          return (
            <>
              <LinkPrevious
                path={`/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>
                {t(
                  'edit-professional-experience.title',
                  'Edit Professional Experience'
                )}
              </Title>
              <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                  {formValues.sharingPermissionsExtended && (
                    <SharingPermissionSelect selectName="sharingPermissionsExtended.profession.id" />
                  )}
                </div>
                <div className="desktop:flex-2 desktop:mr-24">
                  <Form className="max-w-lg">
                    <FieldArray
                      name="professionalExperiences"
                      render={({ push, remove }) => (
                        <>
                          {values.professionalExperiences.map(
                            (profession, i) => (
                              <div
                                key={`${profession}`}
                                className="pb-8 mb-8 border-b border-gray-300 border-dotted"
                              >
                                <TextField
                                  name={`professionalExperiences.${i}.occupation`}
                                  label={`${t(
                                    'edit-professional-experience.occupation',
                                    'Occupation'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <TextField
                                  name={`professionalExperiences.${i}.employer`}
                                  label={`${t(
                                    'edit-professional-experience.employer',
                                    'Employer'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <TextField
                                  name={`professionalExperiences.${i}.position`}
                                  label={`${t(
                                    'edit-professional-experience.position',
                                    'Position'
                                  )}<span class="sr-only"> ${i + 1}</span>`}
                                />
                                <div className="mt-8 flex">
                                  <PrimaryRadio
                                    checked={profession.isPrimary}
                                    id={`primary-radio-${i}`}
                                    name="primaryOccupation"
                                    onClick={() => selectPrimary(i)}
                                    label={`<span class="sr-only">${selectProfessionLabelValue(
                                      values.professionalExperiences[i]
                                    )} </span> ${t(
                                      'edit-professional-experience.primary-label',
                                      'Primary'
                                    )}`}
                                  />
                                  {((multipleProfessions &&
                                    !profession.isPrimary) ||
                                    !multipleProfessions) && (
                                    <DeleteButton onClick={() => remove(i)} />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                          <AddAnotherButton
                            label={t(
                              'edit-professional-experience.add-label',
                              'Add another occupation'
                            )}
                            onClick={() => {
                              push({
                                occupation: '',
                                employer: '',
                                position: '',
                                isPrimary: makePrimary,
                              });
                            }}
                          />
                          <Button
                            full
                            className="mt-16 mb-6"
                            clickHandler={() => {
                              handleSubmit();
                            }}
                          >
                            {t(
                              'edit-professional-experience.form.submit-label',
                              'Save Changes'
                            )}
                          </Button>
                          <Button
                            type="button"
                            full
                            text
                            clickHandler={handleCancel}
                          >
                            {t(
                              'edit-professional-experience.form.cancel-label',
                              'Cancel'
                            )}
                          </Button>
                        </>
                      )}
                    />
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default ProfessionalExperienceEdit;
