import React from 'react';

import { isEmpty } from 'lodash';

import Divider from '@components/Divider';
import LinkEdit from '@components/LinkEdit';

import ResumeRecognitions from './components/ResumeRecognition';
import ResumeRoles from './components/ResumeRoles';
import ResumeSponsorships from './components/ResumeSponsorships';
import ResumeSummary from './components/ResumeSummary';

import {
  getFormattedOriginalAndCurrentClubs,
  orderResumeRolesGroup,
} from '@use-cases/profile';

import { useTranslation } from '@external/react-i18next';

import { ResumeItem } from '@typings/graphql';
import { FetchProfileQuery } from '@typings/operations';

type Props = Pick<
  FetchProfileQuery['individual'],
  'resumeRecognitions' | 'resumeRoles' | 'resumeSponsorships'
> & { hasEditPermission: boolean; individualId: string };

const Resume: React.FC<Props> = ({
  resumeRecognitions,
  resumeRoles,
  resumeSponsorships,
  hasEditPermission,
  individualId,
}) => {
  const { t } = useTranslation();

  const resumeRolesGroups = resumeRoles.resumeRolesGroups || [];

  if (
    !resumeRolesGroups.length &&
    !resumeRecognitions?.length &&
    !resumeSponsorships.sponsoredBySomeone.length &&
    !resumeSponsorships.sponsoredByMe.length
  ) {
    return null;
  }

  const { originalJoinDate, currentClubJoinDate, yearsOfService } = resumeRoles;

  const isResumeSummarySectionShown = !!(originalJoinDate || yearsOfService);

  const {
    originalJoinClub,
    currentJoinClub,
  } = getFormattedOriginalAndCurrentClubs(
    { originalJoinDate, currentClubJoinDate },
    resumeRolesGroups
  );

  const { sponsoredByMe, sponsoredBySomeone } = resumeSponsorships;
  const displayResumeHeaderMessage =
    hasEditPermission &&
    (!!resumeRolesGroups.length ||
      !(isEmpty(sponsoredByMe) && isEmpty(sponsoredBySomeone)));

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <div>
          <h2>{t('view-profile.resume.title', 'Rotary Resume')}</h2>
          {displayResumeHeaderMessage && (
            <div className="mb-6 mt-6 w-4/5">
              {t(
                'view-resume.edit.resume.header.message',
                'Your membership information and sponsorship history are visible to anyone who views your profile. To select who can see your Rotary Foundation recognition, use the edit option.'
              )}
            </div>
          )}
        </div>

        {hasEditPermission && (
          <LinkEdit
            path={`/profile/${individualId}/edit-resume`}
            component={t('view-profile.resume.title', 'Rotary Resume')}
          />
        )}
      </div>

      {!!resumeRolesGroups.length && (
        <div className="mb-10">
          {isResumeSummarySectionShown && (
            <ResumeSummary
              originalJoinDate={originalJoinClub}
              currentClubJoinDate={currentJoinClub}
              yearsOfService={yearsOfService}
            />
          )}
          <h3 className="h4 alternate" id="roles">
            {t('view-profile.resume.roles-title', 'Roles')}
          </h3>
          {orderResumeRolesGroup(resumeRolesGroups).map(
            (resumeRole: ResumeItem, i) => (
              <ResumeRoles
                resumeRole={resumeRole}
                key={`${resumeRole.organizationName + i}`}
              />
            )
          )}
        </div>
      )}

      {!!resumeRecognitions?.length && (
        <div className="mb-10">
          <h3 className="h4 alternate">
            {t(
              'view-profile.resume.recognition-title',
              'Rotary Foundation Recognition'
            )}
          </h3>
          {resumeRecognitions.map((resumeRecognition, i) => (
            <ResumeRecognitions
              resumeRecognition={resumeRecognition}
              key={`${resumeRecognition.type + i}`}
            />
          ))}
        </div>
      )}

      <ResumeSponsorships resumeSponsorships={resumeSponsorships} />
    </>
  );
};

export default Resume;
