// Libs
import React from 'react';

import Divider from '@components/Divider';
import LinkEdit from '@components/LinkEdit';

import { useTranslation } from '@external/react-i18next';

import { FetchProfileQuery } from '@typings/operations';

type Props = {
  areasOfExpertise: FetchProfileQuery['individual']['groupedExpertise'];
  hasEditPermission: boolean;
  individualId: string;
};

const AreasOfExpertise: React.FC<Props> = ({
  areasOfExpertise,
  hasEditPermission,
  individualId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>
          {t('view-profile.areas-of-expertise.title', 'Areas of Expertise')}
        </h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/profile/${individualId}/edit-areas-of-expertise`}
            component={t(
              'view-profile.areas-of-expertise.title',
              'Areas of Expertise'
            )}
          />
        )}
      </div>
      {!!areasOfExpertise?.length &&
        areasOfExpertise.map(areaOfExpertise => (
          <div className="max-w-4xl mb-8" key={areaOfExpertise.level}>
            <h3 className="h5">{areaOfExpertise.level}</h3>
            <ul>
              {areaOfExpertise.areas.map(area => (
                <li key={area.label} className="mb-0 last:mb-4">
                  {area.label}
                  {area.primary &&
                    ` (${t(
                      'view-profile.areas-of-expertise.primary',
                      'Primary'
                    )})`}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </>
  );
};

export default AreasOfExpertise;
