// Libs
import React from 'react';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Components
import Divider from '@components/Divider';
import LinkEdit from '@components/LinkEdit';
import VerifiedTag from '@components/VerifiedTag';

// Typings
import { FetchProfileQuery } from '@typings/operations';

type Props = Pick<FetchProfileQuery['individual'], 'programs'> & {
  individualId: string;
  hasEditPermission: boolean;
};

const RotaryPrograms: React.FC<Props> = ({
  programs,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>{t('view-profile.programs.title', 'Rotary Programs')}</h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/profile/${individualId}/edit-programs`}
            component={t('view-profile.programs.title', 'Rotary Programs')}
          />
        )}
      </div>
      {!!programs.length &&
        programs.map((program, index) => (
          <div key={`${index}${program.title}`} className="mb-8">
            <h3 className="h5">{program.title}</h3>
            {program.fields.map((field, index) => (
              <p
                key={`${index}${field.label}`}
                className="mb-0"
              >{`${field.label}: ${field.value}`}</p>
            ))}
            <VerifiedTag pending={!program.isVerified} />
          </div>
        ))}
    </>
  );
};

export default RotaryPrograms;
