import React from 'react';

import { getFormattedYearsOfService } from '@use-cases/profile';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

type ResumeSummaryProps = {
  originalJoinDate?: Maybe<string>;
  currentClubJoinDate?: Maybe<string>;
  yearsOfService?: Maybe<number>;
};

export const ResumeSummary: React.FC<ResumeSummaryProps> = ({
  originalJoinDate,
  currentClubJoinDate,
  yearsOfService,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {originalJoinDate && (
        <div className="mb-6">
          <h4 className="h5">
            {t('view-profile.resume.original-join-date', 'Original Join Date')}
          </h4>
          <div>{originalJoinDate}</div>
        </div>
      )}
      {currentClubJoinDate && (
        <div className="mb-6">
          <h4 className="h5">
            {t(
              'view-profile.resume.current-club-join-date',
              'Current Club Join Date'
            )}
          </h4>
          <div>{currentClubJoinDate}</div>
        </div>
      )}
      {!!yearsOfService && (
        <div className="mb-6">
          <h4 className="h5">
            {t('view-profile.resume.years-of-service', 'Years of Service')}
          </h4>
          <div>{getFormattedYearsOfService(yearsOfService, t)}</div>
        </div>
      )}
    </>
  );
};

export default ResumeSummary;
