import React from 'react';

import Select from '@components/Formik/Select';
import TextField from '@components/Formik/TextField';
import { InputDate } from '@components/Forms/InputDate';

import { FieldType, ProgramFieldDefinition } from '@typings/graphql';

type Props = ProgramFieldDefinition;

const ProgramField: React.FC<Props> = ({
  fieldId,
  label,
  isRequired,
  type,
  options,
}) => {
  const labelValue = isRequired ? `${label}*` : label;
  if (type === FieldType.Options) {
    return (
      <Select
        name={fieldId}
        label={labelValue}
        additionalClasses="tablet:w-1/2"
        options={
          options
            ? options.map(({ key, value }) => ({
                value: key,
                label: value,
              }))
            : []
        }
      />
    );
  }
  if (type === FieldType.Date) {
    return (
      <InputDate
        name={fieldId}
        label={labelValue}
        id={fieldId}
        className="tablet:w-1/2"
        placeholderDateFormat="DD MMM YYYY"
        selectedDateFormat="dd MMM yyyy"
      />
    );
  }
  if (type === FieldType.String || type === FieldType.Number) {
    return (
      <div className="tablet:w-1/2">
        <TextField name={fieldId} label={labelValue} />
      </div>
    );
  }
  return null;
};

export default ProgramField;
