// Libs
import React from 'react';

import { QueryResult } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

// Components
import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import VerifiedTag from '@components/VerifiedTag';

import EditProgramFields from './components/EditProgramFields';
import Info from './components/Info';

// Utils
import { buildProgramValidationSchema } from '../validationSchema';

import {
  mapFormValuesToUpdateProgramInput,
  mapProgramDataToFormValues,
} from '@domain/profile/mappers';
import { FormValues } from '@domain/profile/types';

import { useNotifications } from '@use-cases/notifications';
import {
  getIndividualProgramById,
  getProgramDefinitionById,
} from '@use-cases/profile/helpers';

import { useUpdateProgram } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { Program, ProgramDefinition } from '@typings/graphql';
import { FetchEditProgramsQuery } from '@typings/operations';

interface Props extends RouteComponentProps {
  individualId: string;
  individualPrograms: Program[];
  programs: ProgramDefinition[];
  individualProgramId?: string;
  refetch: QueryResult<FetchEditProgramsQuery>['refetch'];
}

const EditIndividualProgram: React.FC<Props> = ({
  individualId,
  individualPrograms,
  programs,
  individualProgramId,
  refetch,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const [updateProgram, { loading }] = useUpdateProgram();

  if (!individualProgramId) {
    localizedNavigate(`/profile/${individualId}`);
    return <Loading />;
  }

  const individualProgram = getIndividualProgramById(
    individualPrograms,
    individualProgramId
  );
  const programDefinition = getProgramDefinitionById(
    programs,
    individualProgram.programId
  );

  const handleSubmit = async (values: FormValues) => {
    await updateProgram({
      variables: {
        individualId,
        individualProgramId,
        fields: mapFormValuesToUpdateProgramInput(programDefinition, values),
      },
    });
    await refetch();
    addSuccess(
      t('edit-background.form.success', 'Program updated successfully.'),
      {
        id: 'form.success',
      }
    );
    localizedNavigate(`/profile/${individualId}/edit-programs`);
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}/edit-programs`);
  };

  return (
    <OneColumn>
      <LinkPrevious
        path={`/profile/${individualId}/edit-programs`}
        label={t('edit-profile.programs.back-link', 'Programs')}
      />
      <Title>Edit Rotary Program</Title>
      <Info />
      <span className="font-bold">
        {t('edit-profile.programs.program-title', 'Program Title')}
      </span>
      <p className="mt-2">{programDefinition.title}</p>
      <Formik
        onSubmit={handleSubmit}
        initialValues={mapProgramDataToFormValues(
          programDefinition,
          individualProgram
        )}
        validationSchema={buildProgramValidationSchema(t, programDefinition)}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <EditProgramFields programDefinition={programDefinition} />
              <p className="my-8 text-sm text-gray-500">
                <VerifiedTag pending={!individualProgram.isVerified} />
                {t(
                  'edit-profile.programs.edit.verification',
                  'This information will be sent to Rotary for verification.'
                )}
              </p>
              <Button disabled={loading || isSubmitting}>
                {t('edit-profile.programs.save-button-label', 'Save Changes')}
              </Button>
              <Button secondary type="button" clickHandler={handleCancel}>
                {t('edit-profile.programs.cancel-button-label', 'Cancel')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default EditIndividualProgram;
