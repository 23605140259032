import React from 'react';

import moment from 'moment';

import { Avatar } from '@components/Avatar';
import Link from '@components/Link';
import LinkEdit from '@components/LinkEdit';
import LinkMessage from '@components/LinkMessage';
import Title from '@components/Title';

import { ClubAffiliationDetails, getClubAffiliationDetails } from './utils';

import { getClubDetailsPath } from '@use-cases/clubs';

import { constructFullName } from '@utils/constructFullName';
import { getClubTypeName } from '@utils/getClubTypeName';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/graphql';
import { ClubAffiliation, FetchProfileQuery } from '@typings/operations';

export type PersonalDetailsProps = Pick<
  FetchProfileQuery['individual'],
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'localizedName'
  | 'riIndividualId'
  | 'nfKey'
  | 'location'
  | 'photoUri'
  | 'clubAffiliations'
  | 'suffix'
  | 'prefix'
  | 'gender'
  | 'dateOfBirth'
  | 'youthFlag'
> & {
  individualId: string;
  hasEditPermission: boolean;
  isResumeRolesExists: boolean;
};

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  firstName,
  lastName,
  middleName,
  localizedName,
  photoUri,
  clubAffiliations,
  riIndividualId,
  individualId,
  nfKey,
  hasEditPermission,
  suffix,
  prefix,
  gender,
  dateOfBirth,
  youthFlag,
  isResumeRolesExists,
}) => {
  const { t } = useTranslation();

  const clubAffilicationsDetails = getClubAffiliationDetails(
    clubAffiliations as ClubAffiliation[]
  );

  const renderClubAffiliationDetails = (club: ClubAffiliationDetails) => {
    const { clubId, clubName, clubType, clubLocation, membership } = club;

    const isMember =
      membership?.membershipType === MembershipType.Member ||
      membership?.membershipType === MembershipType.Rotaractor;

    const memberTypeName = isMember
      ? t('membership.type.full-member', 'Member')
      : t('membership.type.honorary', 'Honorary');

    return (
      <p className="mb-0" key={clubId}>
        <Link to={getClubDetailsPath(clubId)}>
          <b>{clubName}</b>
        </Link>

        {', '}

        <span>
          {clubLocation} ({clubType ? getClubTypeName(t, clubType) : ''})
        </span>

        {membership && ', '}

        <span>{membership ? memberTypeName : ''}</span>
      </p>
    );
  };

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });

  return (
    <div className="desktop:flex desktop:items-center border-b-4 border-dark-blue-400 pb-4 desktop:pb-10 mb-6">
      <div className="flex justify-center mb-4 desktop:mr-4">
        <Avatar
          alt={firstName || lastName || ''}
          size="big"
          photoUri={photoUri || undefined}
        />
      </div>
      <div className="w-full">
        <div className="flex items-baseline">
          <Title className="mr-auto mb-3">{fullName}</Title>
          {hasEditPermission && (
            <LinkEdit
              path={`/profile/${individualId}/edit-personal`}
              component={t(
                'view-profile.personal-details.title',
                'Personal details'
              )}
            />
          )}
        </div>
        <div className="flex items-baseline">
          <div className="mr-auto">
            {clubAffilicationsDetails.map(renderClubAffiliationDetails)}

            {isResumeRolesExists && (
              <Link to="#roles">
                {t('view-profile.personal-details.show-roles', 'Show roles')}
              </Link>
            )}

            {(gender || dateOfBirth) && (
              <p className="mb-0">
                {gender && gender !== 'NotGiven' && `${gender}, `}
                {!youthFlag && dateOfBirth
                  ? moment(dateOfBirth).format('DD MMMM YYYY')
                  : ''}
              </p>
            )}
            {(riIndividualId || localizedName) && (
              <p className="mb-0">
                {localizedName && (
                  <span className="font-bold">
                    {localizedName}
                    {riIndividualId && `, `}
                  </span>
                )}
                {riIndividualId && `ID: ${riIndividualId}`}
              </p>
            )}
          </div>
          <LinkMessage addresseeNFKey={nfKey} />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
