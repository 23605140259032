import React from 'react';

import { Router } from '@reach/router';

import RequireLogin from '@components/Auth/RequireLogin';
import NotFound from '@components/Routing/NotFound';
import AreasOfExpertise from '@presenters/web/pages/Profile/Edit/AreasOfExpertise';
import BackgroundEdit from '@presenters/web/pages/Profile/Edit/Background';
import ContactInformationEdit from '@presenters/web/pages/Profile/Edit/ContactInformation';
import PersonalDetailsEdit from '@presenters/web/pages/Profile/Edit/PersonalDetails';
import ProfessionalExperienceEdit from '@presenters/web/pages/Profile/Edit/ProfessionalExperience';
import AddProgram from '@presenters/web/pages/Profile/Edit/Programs/AddProgram';
import EditIndividualProgram from '@presenters/web/pages/Profile/Edit/Programs/EditIndividualProgram';
import EditPrograms from '@presenters/web/pages/Profile/Edit/Programs/EditPrograms';
import ProgramsList from '@presenters/web/pages/Profile/Edit/Programs/ProgramList';
import ResumeEdit from '@presenters/web/pages/Profile/Edit/Resume';
import MyProfile from '@presenters/web/pages/Profile/View/MyProfile';
import ProfileView from '@presenters/web/pages/Profile/View/ProfileView';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const ProfilePage: React.FC<{ pageContext: { languagePrefix: string } }> = ({
  pageContext: { languagePrefix },
}) => {
  const { t } = useTranslation();
  return (
    <RequireLogin>
      <Helmet
        titleTemplate={`${t('metadata.title.profile', 'Profile')}: %s | ${t(
          'metadata.title.default',
          'My Rotary'
        )}`}
      />
      <Router basepath={`/${languagePrefix}`}>
        <MyProfile path="/profile/me" />
        <ProfileView path="/profile/:individualId" />
        <PersonalDetailsEdit
          path="/profile/:individualId/edit-personal"
          individualId="is set from the path"
        />
        <BackgroundEdit path="/profile/:individualId/edit-background" />
        <ContactInformationEdit path="/profile/:individualId/edit-contact" />
        <ProfessionalExperienceEdit
          path="/profile/:individualId/edit-professional-experience"
          individualId="is set from the path"
        />
        <AreasOfExpertise
          path="/profile/:individualId/edit-areas-of-expertise"
          individualId="is set from the path"
        />
        <ResumeEdit path="/profile/:individualId/edit-resume" />
        <EditPrograms
          path="/profile/:individualId/edit-programs/*"
          render={({
            individualId,
            individualPrograms,
            programs,
            refetch,
            sharingPermissionsExtended,
          }) => (
            <>
              <ProgramsList
                path="/"
                {...{
                  individualId,
                  individualPrograms,
                  sharingPermissionsExtended,
                  refetch,
                }}
              />
              <EditIndividualProgram
                path=":individualProgramId/edit"
                {...{ individualId, programs, individualPrograms, refetch }}
              />
              <AddProgram path="add" {...{ individualId, programs, refetch }} />
            </>
          )}
        />
        <NotFound default />
      </Router>
    </RequireLogin>
  );
};

export default ProfilePage;
