export const membershipTypes = ['Member', 'Honorary'];

export const acceptedImageTypes = [
  'png',
  'jpeg',
  'gif',
  'bmp',
  'tiff',
  'svg+xml',
  'webp',
  'heic',
];

export const maxImageSize = 2 * 1024 * 1024;
