// Libs
import React from 'react';

// Types
import { Profession } from '@typings/graphql';

const Experience: React.FC<{ profession: Profession }> = ({ profession }) => {
  const { occupation, position, employer } = profession;

  return (
    <p>
      {occupation && <span className="h5">{occupation}</span>}
      {employer && <span className="block">{employer}</span>}
      {position && <span className="block">{position}</span>}
    </p>
  );
};

export default Experience;
