// Libs
import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';

// Components
import Loading from '@components/Loading';

// Utils
import { navigate } from 'gatsby';

// Hooks
import { useAppConfig } from '@hooks/appConfig';

const MyProfile: React.FC<RouteComponentProps> = () => {
  const { user } = useAppConfig();
  useEffect(() => {
    navigate(`/profile/${user?.individualId}`, { replace: true });
  }, [user]);
  return <Loading />;
};

export default MyProfile;
