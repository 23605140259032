import React from 'react';
import { useTranslation } from '@external/react-i18next';
import Icon from '@components/Icon';

interface Props {
  pending?: boolean;
}

const VerifiedTag: React.FC<Props> = ({ pending }) => {
  const { t } = useTranslation();

  return (
    <p
      className={`flex items-center mt-4 h5 alternate ${
        pending ? 'text-red-300' : 'text-green-300'
      }`}
    >
      <Icon
        name={`circles/${pending ? 'pending' : 'check'}`}
        color={`${pending ? 'red-300' : 'green-300'}`}
        size="18"
        className="inline mr-3"
      />
      {pending
        ? t('view-profile.programs.not-verified', 'Pending verification')
        : t('view-profile.programs.verified', 'Verified')}
    </p>
  );
};

export default VerifiedTag;
