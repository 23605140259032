// Libs
import React, { ReactChildren, ReactChild } from 'react';

// Components
import { Button } from '@components/Button';
import TableWrapper from '@components/Table';

// Types
import { Program } from '@typings/graphql';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface LinkButtonProps {
  onClick: () => void;
  children: ReactChildren | ReactChild;
}
const LinkButton: React.FC<LinkButtonProps> = ({ onClick, children }) => (
  <a onClick={onClick}>{children}</a>
);

interface VerificationTagProps {
  isVerified: boolean;
}
const VerificationTag: React.FC<VerificationTagProps> = ({ isVerified }) => {
  const { t } = useTranslation();
  if (isVerified) {
    return (
      <span className="h5 alternate text-green-300">
        {t('edit-profile.programs.verification.verified', 'Verified')}
      </span>
    );
  }
  return (
    <span className="h5 alternate text-red-300">
      {t('edit-profile.programs.verification.pending', 'Pending')}
    </span>
  );
};

interface Props {
  programs: Program[];
  handleDelete: (individualProgramId: string) => void;
  handleEdit: (individualProgramId: string) => void;
  handleAdd: () => void;
}
const ProgramsTable: React.FC<Props> = ({
  programs,
  handleEdit,
  handleDelete,
  handleAdd,
}) => {
  const { t } = useTranslation();
  return (
    <TableWrapper>
      <table>
        <thead>
          <tr>
            <th>
              {t(
                'edit-profile.programs.table-header.program-name',
                'Program Name'
              )}
            </th>
            <th>
              {t('edit-profile.programs.table-header.start-date', 'Start Date')}
            </th>
            <th>
              {t('edit-profile.programs.table-header.end-date', 'End Date')}
            </th>
            <th>
              {t(
                'edit-profile.programs.table-header.verification-status',
                'Verification Status'
              )}
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {programs.map(
            ({
              startDate,
              endDate,
              individualProgramId,
              title,
              isVerified,
            }) => (
              <tr key={individualProgramId}>
                <td>{title}</td>
                <td>{startDate!}</td>
                <td>{endDate!}</td>
                <td>
                  <VerificationTag isVerified={isVerified} />
                </td>
                <td>
                  <LinkButton onClick={() => handleEdit(individualProgramId)}>
                    <span>
                      {t('edit-profile.programs.edit-button.label', 'Edit')}
                    </span>
                  </LinkButton>
                </td>
                <td>
                  <LinkButton onClick={() => handleDelete(individualProgramId)}>
                    <span>
                      {t('edit-profile.programs.delete-button.label', 'Delete')}
                    </span>
                  </LinkButton>
                </td>
              </tr>
            )
          )}
          <tr>
            <td colSpan={6} className="text-right">
              <Button clickHandler={handleAdd} icon="ui/plus" small secondary>
                {t('edit-profile.programs.form.add-new', 'Add new program')}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default ProgramsTable;
