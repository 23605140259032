// Libs
import axios from 'axios';

// Hooks
import useSWR from 'swr';

// Utils
import { getIsDev } from '@domain/language-negotiation';

// Constants
import { defaultLanguage } from 'languages';

// Types
import { Drupal } from '@typings/drupal';

const fetcher = async (
  language: string,
  senderNFKey: string,
  addresseeNFKey: string
) => {
  if (!(senderNFKey && addresseeNFKey)) {
    return { data: { forbidden: 'true' } };
  }

  const url = `${process.env.GATSBY_BACKEND_APP_BASE_URL}/${
    getIsDev(language) ? defaultLanguage : language
  }/restapi/privatemsg/${senderNFKey}/${addresseeNFKey}`;

  const response = await axios.get(url, { withCredentials: true });

  return response.data;
};

export const useMessaging = (
  language: string,
  senderNFKey?: string | null,
  addresseeNFKey?: string | null
): Drupal.CustomResponseValues<{
  forbidden?: string;
  from?: string;
  to?: string;
}> => {
  const { data, error } = useSWR(
    [language, senderNFKey, addresseeNFKey],
    fetcher
  );
  return { data, error, loading: !data };
};
