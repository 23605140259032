import React from 'react';

import LinkEdit from '@components/LinkEdit';

import ContactInformationAddress from './ContactInformationAddress';
import ContactInformationEmail from './ContactInformationEmail';
import ContactInformationPhone from './ContactInformationPhone';

import { useTranslation } from '@external/react-i18next';

import { FetchProfileQuery } from '@typings/operations';

type Props = Pick<
  FetchProfileQuery['individual'],
  | 'primaryEmail'
  | 'otherEmails'
  | 'primaryAddress'
  | 'otherAddresses'
  | 'primaryPhone'
  | 'otherPhones'
> & { individualId: string; hasEditPermission: boolean };

const ContactInformation: React.FC<Props> = ({
  primaryEmail,
  primaryAddress,
  primaryPhone,
  otherEmails,
  otherAddresses,
  otherPhones,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-start">
        <h2>{t('contact-information.title', 'Contact Information')}</h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/profile/${individualId}/edit-contact`}
            component={t('contact-information.title', 'Contact Information')}
          />
        )}
      </div>
      {primaryEmail && (
        <>
          <h3 className="h5">
            {t('contact-information.email-primary', 'Primary Email')}
          </h3>
          <ContactInformationEmail email={primaryEmail} />
        </>
      )}
      {!!otherEmails?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.alternate-email', 'Alternate Email')}
          </h3>
          {otherEmails.map(email => (
            <div key={email.address}>
              <ContactInformationEmail className="mt-2" email={email} />
            </div>
          ))}
        </div>
      )}
      {primaryPhone && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.phone-primary', 'Primary Phone')}
          </h3>
          <ContactInformationPhone phone={primaryPhone} />
        </div>
      )}
      {!!otherPhones?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.alternate-phone', 'Alternate Phone')}
          </h3>
          {otherPhones.map(phone => (
            <ContactInformationPhone
              className="mt-2"
              phone={phone}
              key={phone.number}
            />
          ))}
        </div>
      )}
      {primaryAddress && (
        <div className="mt-10">
          <ContactInformationAddress
            address={primaryAddress}
            label={t('contact-information.address-primary', 'Primary Address')}
          />
        </div>
      )}
      {!!otherAddresses?.length && (
        <>
          {otherAddresses.map(address => {
            return (
              <div className="mt-10" key={`${address.lineOne} ${address.type}`}>
                <ContactInformationAddress
                  address={address}
                  label={t(
                    'contact-information.address-alternate',
                    'Alternate Address'
                  )}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default ContactInformation;
