import React from 'react';

import { getCurrentTermStartYear } from '@utils/officers';

import { RecognitionItem } from '@typings/graphql';

const ResumeRecognitions: React.FC<{ resumeRecognition: RecognitionItem }> = ({
  resumeRecognition,
}) => {
  return (
    <div className="mb-6">
      <h4>{resumeRecognition.type}</h4>
      {resumeRecognition.recognitions.map((recognition, index) => {
        const recognitionDate = new Date(recognition.date);
        const recognitionKey = `recognition-${index}`;

        return (
          <div key={recognitionKey}>
            <span>{recognition.description}</span>
            <span>
              {' '}
              ({getCurrentTermStartYear(recognitionDate)}-
              {(getCurrentTermStartYear(recognitionDate) + 1)
                .toString()
                .slice(-2)}
              )
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ResumeRecognitions;
