// Libs
import React from 'react';

// Components
import { FieldArray } from 'formik';

import AddAnotherButton from '../../components/ButtonAddAnother';
import DeleteButton from '../../components/ButtonDelete';
import PrimaryRadio from '../../components/PrimaryRadio';
import { getNewEmail } from '../utils';
import EmailField from './EmailField';

// Hooks
import { ContactInformationFormValues } from '@domain/profile';

import { useTranslation } from '@external/react-i18next';

// Types

// Utils

interface Props {
  selectPrimary: (selectedIndex: number) => void;
  emails: ContactInformationFormValues['emails'];
}

const Form: React.FC<Props> = ({ emails, selectPrimary }) => {
  const { t } = useTranslation();

  return (
    <div className="max-w-lg">
      <FieldArray
        name="emails"
        render={({ push, remove }) => {
          return (
            <>
              {emails.map((e, i) => {
                const customKey = `email${i}`;
                return (
                  <div className="mt-8 first:mt-0 mb-8" key={customKey}>
                    <EmailField
                      index={i}
                      isSignin={e.isSignin}
                      address={e.address}
                    />
                    <div className="flex justify-between">
                      <PrimaryRadio
                        checked={e.isPrimary}
                        id={`primary-email-radio-${i}`}
                        onClick={() => selectPrimary(i)}
                        label={t(
                          'edit-contact-information.email.primary-label',
                          'Primary Email for RI communication'
                        )}
                      />
                      {!e.isPrimary && !e.isSignin && (
                        <DeleteButton onClick={() => remove(i)} />
                      )}
                    </div>
                  </div>
                );
              })}
              <AddAnotherButton
                label={t(
                  'edit-contact-information.email.add-label',
                  'Add another email'
                )}
                onClick={() => push(getNewEmail())}
                color="black"
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default Form;
