// Libs
import React from 'react';

// Types
import { ProgramDefinition } from '@typings/graphql';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Components
import ProgramField from './ProgramField';

interface Props {
  programDefinition: ProgramDefinition;
}

const EditProgramFields: React.FC<Props> = ({ programDefinition }) => {
  const { t } = useTranslation();
  return (
    <div>
      {programDefinition.fieldGroups.map(
        ({ translationKey, defaultTranslation, fields }) => (
          <div key={translationKey}>
            <h3 className="uppercase">
              {t('programs.field-group.*', '{fieldGroup}', {
                fieldGroup: {
                  key: translationKey,
                  default: defaultTranslation,
                },
                context: 'fieldGroup',
              })}
            </h3>
            {fields.map(f => (
              <ProgramField key={f.fieldId} {...f} />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default EditProgramFields;
