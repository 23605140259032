import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import ReactDatePicker from 'react-datepicker';

import { Icon } from '../../Icon';
import { FormElement } from '../FormElement';
import { Select } from '../Select';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase, ValueType } from '@external/react-select';

// TODO: finish Date props
export const InputDate: React.FC<{
  id: string;
  name: string;
  value?: string;
  ref?: (instance: ReactDatePicker) => void;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  label: string;
  labelHidden?: boolean;
  description?: string;
  required?: boolean;
  resetValue?: boolean;
  magnifier?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  initialDateValue?: Date;
  forceSetTouched?: boolean;
  showDefaultDate?: boolean;
  placeholderDateFormat?: string;
  selectedDateFormat?: string;
  validation?: (date: Moment) => string | undefined;
}> = ({
  id,
  name,
  value,
  ref,
  minDate,
  maxDate,
  className,
  label,
  labelHidden,
  description,
  required,
  resetValue,
  magnifier,
  disabled,
  error,
  errorMessage,
  initialDateValue,
  forceSetTouched,
  showDefaultDate = false,
  placeholderDateFormat = 'MM/DD/YYYY ',
  selectedDateFormat = 'MM/dd/yyyy ',
  validation,
}) => {
  const [field, meta, helpers] = useField<Date>(name);
  const defaultDate = new Date();

  // Run the validation handler.
  const localErrorMessage = validation && value && validation(moment(value));
  const { t } = useTranslation();

  const parsingDateFormat: string = 'YYYY-MM-DD';

  const years: OptionTypeBase[] = [];
  const currentYear = moment().year();
  const minDateYear = minDate ? moment(minDate).year() : 1900;
  const maxDateYear = maxDate ? moment(maxDate).year() : currentYear + 20;

  for (let index = minDateYear; index <= maxDateYear; index++) {
    years.push({ value: String(index), label: String(index) });
  }

  // TODO: Add months translations
  const months: OptionTypeBase[] = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
  ];
  useEffect(() => {
    if (showDefaultDate) {
      helpers.setValue(defaultDate);
    }
    if (initialDateValue) {
      helpers.setValue(initialDateValue);
    }
  }, []);

  const handleChange = (date: Date) => {
    helpers.setValue(date);

    if (forceSetTouched) {
      setTimeout(() => {
        helpers.setTouched(true);
      }, 0);
    }
  };

  return (
    <FormElement
      id={id}
      className={className}
      label={label}
      labelHidden={!!labelHidden}
      description={description}
      required={required}
      disabled={disabled}
      error={error || !!localErrorMessage}
      errorMessage={errorMessage || localErrorMessage}
      classes={{ elementWrapper: 'relative h-11 block' }}
      suffix={
        <div className="absolute bottom-0 h-6 w-6 m-auto mr-4 right-0 top-0 pointer-events-none">
          <Icon name="ui/calendar" color="gray-400" />
        </div>
      }
    >
      <ReactDatePicker
        className={classNames(
          'border border-gray-300 focus:border-dark-blue-400 rounded-sm w-full py-3 px-4 text-xs leading-loose',
          {
            'pl-10': magnifier,
            'pr-10': resetValue,
            'bg-gray-100 cursor-not-allowed placeholder-gray-300': disabled,
            'placeholder-gray-400': !disabled,
            'bg-red-100 border-red-600': !!meta.error && meta.touched,
          }
        )}
        name={name}
        disabled={disabled}
        required={required}
        ref={ref}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholderDateFormat}
        dateFormat={selectedDateFormat}
        openToDate={
          field.value
            ? moment(field.value, parsingDateFormat).toDate()
            : new Date()
        }
        todayButton={t('forms.datepicker.today-label', 'Select today')}
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Select
              id="select-month"
              name="select-month"
              label="Select Month"
              labelHidden
              noMargins
              options={months}
              className="w-2/3 tablet:w-2/6 mr-3"
              defaultValue={
                months[moment(field.value, parsingDateFormat).month()]
              }
              onChange={(selectedOption?: ValueType<OptionTypeBase>) => {
                const selectedMonthIndex = months.findIndex(
                  element =>
                    element.label === (selectedOption as OptionTypeBase)?.label
                );
                changeMonth(selectedMonthIndex);
              }}
            />

            <Select
              id="select-year"
              name="select-year"
              label="Select Year"
              labelHidden
              noMargins
              options={years}
              className="w-1/3 tablet:w-1/5"
              defaultValue={
                years[
                  years.findIndex(
                    element =>
                      Number(element.label) ===
                      moment(field.value, parsingDateFormat).year()
                  )
                ]
              }
              onChange={(selectedOption?: ValueType<OptionTypeBase>) => {
                changeYear(Number((selectedOption as OptionTypeBase)?.value));
              }}
            />
          </div>
        )}
        onChange={handleChange}
        selected={showDefaultDate ? field.value || defaultDate : field.value}
      />
    </FormElement>
  );
};
