import { TFunction } from 'i18next';

import { getEndRYYear, getStartRYYear, getYear } from '@utils/datetime';

export const getResumeRoleDateRange = (
  t: TFunction,
  role: string,
  startDate: string,
  endDate?: string | null
) => {
  const presentLabel = t('view-profile.resume.present', '{{ date }}- Present', {
    date: getYear(startDate),
  });

  if (role === 'Charter Member') {
    return getYear(startDate);
  }

  if (
    role === 'Member' ||
    role === 'Honorary Member' ||
    role === 'Rotaractor'
  ) {
    return endDate ? `${getYear(startDate)}-${getYear(endDate)}` : presentLabel;
  }

  return endDate
    ? `${getStartRYYear(startDate)}-${getEndRYYear(endDate)}`
    : presentLabel;
};
