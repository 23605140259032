import React from 'react';

import classNames from 'classnames';
import { orderBy } from 'lodash';

import { ResumeRoleItem } from '@use-cases/profile';
import { getResumeRoleDateRange } from '@use-cases/profile/helpers/getResumeRoleDateRange';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

type Props = {
  roles: ResumeRoleItem[];
  isHidden: (roleIndex?: number) => boolean;
  isPadding: boolean;
};

const RolesList: React.FC<Props> = ({ roles, isPadding, isHidden }) => {
  const { t } = useTranslation();

  return (
    <>
      {roles.map(({ role, startDate, terms }, roleIndex) => (
        <p
          className={classNames(`${isPadding ? 'p-0 m-0 pl-2' : 'p-0 m-0'}`, {
            hidden: isHidden(roleIndex),
          })}
          key={`${role}-${startDate}`}
        >
          {`${getClubRoleTValue(t, role)} (${orderBy(terms, 'startDate', 'asc')
            ?.map(({ startDate, endDate }) =>
              getResumeRoleDateRange(t, role, startDate, endDate)
            )
            .join(', ')})`}
        </p>
      ))}
    </>
  );
};

export default RolesList;
